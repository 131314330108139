import React from "react"
import { Link, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  pos: {
    marginBottom: theme.spacing(2),
  },
}))

const Blog = ({ data }) => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Blog" />
      <Typography variant="h3" gutterBottom>
        Blog
      </Typography>
      <Typography variant="body1" gutterBottom className={classes.pos}>
        {data.allMarkdownRemark.totalCount} posts
      </Typography>
      <div className={classes.root}>
        <Grid container spacing={4}>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Grid item xs={12} key={node.id}>
              <Card>
                <CardContent>
                  <Typography variant="h5">{node.frontmatter.title}</Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.pos}
                  >
                    {node.frontmatter.date}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {node.excerpt}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Link to={node.fields.slug}>
                    <Button size="small" color="primary">
                      Read More
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMM D, YYYY")
            title
          }
          fields {
            slug
          }
          id
          excerpt(pruneLength: 280)
        }
      }
      totalCount
    }
  }
`

export default Blog
